import * as React from 'react';
import { lazy, Suspense, useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import type { HeadFC } from 'gatsby';
import Layout from '../components/layout/Layout';
import Featured from '../components/Products/Featured';
// const VideoPlayer = lazy(
//   () => import("../components/common/Video/VideoPlayer")
// );
import VideoPlayer from '../components/common/Video/VideoPlayer';
import Banner from '../components/layout/Banner/Banner';
import Category from '../components/Category';
import WhyChoose from '../components/WhyChoose/WhyChoose';
import HomeFaq from '../components/HomeFaq/HomeFaq';
import { CartItem, ProductCategory } from '../types';
import Seo from '../components/Seo';
import NewsLetter from '../components/NewsLetter/NewsLetter';
import ReviewSection from '../components/Review/ReviewSection';
import parse from 'html-react-parser';
import { getQueryString, removeMetaTag } from '../utils/helper';
import { useSelector } from 'react-redux';
import { rootReducer } from '../store';

const IndexPage: React.FC<{ data: any }> = ({ data }) => {
  const [loadFeatured, setLoadFeatured] = useState(false);
  const [isGooglePageSpeedBot, setIsGooglePageSpeedBot] = useState(false);
  const cartItems: CartItem[] = useSelector(
    (state: rootReducer) => state.cart.items
  );
  const couponCode = getQueryString('apply_coupon');

  // Get home page data from GraphQL query
  const homePageInfo = data.allWpPage.edges[0].node.homePage;
  console.log(homePageInfo, '<<-- homePageInfo');
  const homeReviews = data.reviews.nodes;
  // const schema = data.allWpPage.edges[0].node.homePageSchemaData;
  const videoData = data.allWpVideo.edges;
  const categoryList: ProductCategory[] = data.categoryList.nodes;

  // useEffect(() => {
  //   console.log(location, "<<-- location")
  //   if (couponCode) {
  //     navigate(`/checkout/${location.search}&apply_coupon=${couponCode}`);
  //   }
  // }, [couponCode]);

  useEffect(() => {
    console.log(data.reviews.nodes, '=====>>>>');
    // Handle review delay and featured content loading after 3 seconds
    const timer = setTimeout(() => {
      setLoadFeatured(true);
    }, 3000);

    // Clean up timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Detect Google PageSpeed Bot using the User-Agent string
    const userAgent = navigator.platform;
    if (
      userAgent.includes('Chrome-Lighthouse') ||
      userAgent.includes('Lighthouse')
    ) {
      setIsGooglePageSpeedBot(true);
    }

    // Remove "q" query parameter from the URL if it exists
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('q')) {
      urlParams.delete('q');
      window.history.replaceState(
        {},
        document.title,
        `${window.location.pathname}?${urlParams.toString()}`
      );
    }
  }, []);

  return (
    <Layout>
      <Banner pageData={homePageInfo} />
      <Category
        homePageInfo={homePageInfo.categorySection}
        categoryList={categoryList}
      />
      <Featured />
      <WhyChoose pageData={homePageInfo.centerBanner} />
      <VideoPlayer
        SectionTitle="Check our latest videos from"
        platform="/youtube.png"
        videos={videoData}
      />
      <ReviewSection allReview={homeReviews} />
      <HomeFaq />
      <NewsLetter />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query HeaderQuery {
    reviews: allYotpoProductReview(filter: { score: { gt: 3 } }, limit: 5) {
      nodes {
        productIdentifier
        score
        sentiment
        votesUp
        votesDown
        title
        name
        email
        reviewerType
        content
      }
    }
    categoryList: allWcProductsCategories(
      limit: 8
      filter: {
        name: {
          nin: ["Uncategorized", "Discounted upsell products", "Dummy Products"]
        }
      }
    ) {
      nodes {
        id
        name
        slug
        description
        image {
          name
          alt
          localFile {
            url
          }
        }
      }
    }
    allWpVideo(sort: { modifiedGmt: DESC }) {
      edges {
        node {
          title
          id
          videoInfor {
            videoUrl {
              target
              title
              url
            }
          }
        }
      }
    }
    allWpPage(filter: { isFrontPage: { eq: true } }) {
      edges {
        node {
          seo {
            fullHead
          }
          homePageSchemaData {
            homepageSchema
          }
          homePage {
            ageVerificationModal {
              logo {
                node {
                  sourceUrl
                }
              }
              modalBody
              termsAndConditions
            }
            primaryBanner {
              primaryBannerBackgroundImage {
                node {
                  sourceUrl
                  title
                }
              }
              primaryBannerDescription
              primaryBannerButtonText
              primaryBannerButtonUrl {
                target
                title
                url
              }
              primaryBannerImage {
                node {
                  sourceUrl
                  title
                }
              }
            }
            pencilBannerBox {
              optionFiveTitle
              optionFourTitle
              optionThreeTitle
              optionTwoTitle
              optionOneTitle
              optionOneIcon {
                node {
                  sourceUrl
                }
              }
              optionTwoIcon {
                node {
                  sourceUrl
                }
              }
              optionThreeIcon {
                node {
                  sourceUrl
                }
              }
              optionFourIcon {
                node {
                  sourceUrl
                }
              }
              optionFiveIcon {
                node {
                  sourceUrl
                }
              }
            }
            ogImage {
              node {
                id
                sourceUrl
              }
            }
            categorySection {
              buttonText
              description
              buttonUrl {
                target
                title
                url
              }
            }
            centerBanner {
              bannerImage {
                node {
                  sourceUrl
                }
              }
              bottomInformationOne
              bottomInformationTwo
              description
              informationFourDescription
              informationFourTitle
              informationFourIcon {
                node {
                  sourceUrl
                }
              }
              informationOneDescription
              informationOneTitle
              informationOneIcon {
                node {
                  sourceUrl
                }
              }
              informationThreeDescription
              informationThreeTitle
              informationThreeIcon {
                node {
                  sourceUrl
                }
              }
              informationTwoDescription
              informationTwoTitle
              informationTwoIcon {
                node {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const Head: HeadFC = ({ data }: any) => {
  const schema = data.allWpPage.edges[0].node.homePageSchemaData.homepageSchema;
  const seoInfo = data.allWpPage.edges[0].node.seo.fullHead;
  const parsedHtml: any = parse(seoInfo);
  const OGImageUrl =
    data.allWpPage.edges[0].node.homePage?.ogImage?.node?.sourceUrl;
  // Helper function to remove meta tags
  // const removeMetaTag = (property: string) => {
  //   const index = parsedHtml.findIndex(
  //     (item: any) => item?.props?.property === property
  //   );
  //   if (index > -1) parsedHtml.splice(index, 1);
  // };

  // Helper function to remove <script> tags based on `type` or `src`
  const removeScriptTag = (type: string | null) => {
    const index = parsedHtml.findIndex(
      (item: any) => item?.props?.type === type
    );
    if (index > -1) {
      parsedHtml.splice(index, 1);
      console.log(`Removed script tag with type: ${type}`);
    } else {
      console.warn(`Script tag not found with type: ${type}`);
    }
  };

  removeScriptTag('application/ld+json');
  // Clean up SEO-related meta tags
  [
    'og:image',
    'og:image:secure_url',
    'og:image:width',
    'og:image:height',
    'og:image:type',
  ].map((item) => removeMetaTag(parsedHtml, item));
  // removeMetaTag(parsedHtml, 'og:image');
  // removeMetaTag(parsedHtml, 'og:image:secure_url');
  // removeMetaTag(parsedHtml, 'og:image:width');
  // removeMetaTag(parsedHtml, 'og:image:height');
  // removeMetaTag(parsedHtml, 'og:image:type');

  return (
    <>
      {parsedHtml}
      <Seo info={seoInfo} Schema={schema} />
      {/* <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=2.5, user-scalable=no" /> */}
      <meta property="og:image" content={OGImageUrl} />
      <meta property="og:image:width" content="256" />
      <meta property="og:image:height" content="256" />
      <meta property="og:image:type" content="image/png" />
    </>
  );
};
