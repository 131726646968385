import React, { FC, useEffect, useState } from 'react';
import BannerTop from './BannerTop';
import Link from '../../Link';
import { useLocation } from '@reach/router';

interface Props {
  pageData: any;
}

const Banner: FC<Props> = ({ pageData }) => {
  const location = useLocation();
  const bannerData = pageData.primaryBanner;
  // const [isMobile, setIsMobile] = useState<boolean | null>(null); // Initially null to differentiate from server side

  // useEffect(() => {
  //   // Make sure this runs only on the client side
  //   if (typeof window !== 'undefined') {
  //     // Handle screen resize logic
  //     const handleResize = () => {
  //       setIsMobile(window.matchMedia('(max-width: 767px)').matches);
  //     };

  //     // Initial check
  //     handleResize();

  //     // Add resize event listener
  //     window.addEventListener('resize', handleResize);

  //     // Clean up listener on component unmount
  //     return () => window.removeEventListener('resize', handleResize);
  //   }
  // }, []);

  // // Check if the component is mounted (client-side rendering)
  // if (isMobile === null) {
  //   return null; // You can return a loading indicator or placeholder here
  // }

  // Image sources
  const desktopImageSrc =
    bannerData?.primaryBannerImage?.node?.sourceUrl ||
    `${process.env.GATSBY_SITE_URL}/wp-content/uploads/2024/09/banner-pr-min.webp`;
  const mobileImageSrc = `${process.env.GATSBY_SITE_URL}/wp-content/uploads/2024/09/banner-pr-min-mob-1.webp`;

  return (
    <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] contain">
      <BannerTop />
      <div className="banner-bg">
        <div className="lg:pt-[40px] lg:pb-[50px] md:pt-[42px] md:pb-[60] pt-[10px] flex md:flex-row flex-col justify-center items-center z-10 relative">
          {/* Text section */}
          <div className="flex flex-col xl:w-1/2 lg:w-[60%] md:w-[60%] w-full justify-center min-h-[320px] banner-text md:order-1 order-2">
            <div className="min-h-[230px]">
              <h1>
                <span>Unlock the future </span>
                <span>of research with</span>
                <span> Spartan Peptides</span>
              </h1>
              <p>
                Spartan Peptides operates with transparency and integrity,
                providing detailed information about their products, including
                their composition and purity.
              </p>
            </div>
            <Link
              preserveQueryParams={true}
              className="bg-black font-sofiaRegular font-regular md:mt-[10px] w-[205px] md:px-[20px] px-[40px] md:py-2 py-4 rounded-[50px] text-white h-[57px] text-[18px] hover:bg-primary flex items-center justify-center leading-[1]"
              to={
                bannerData?.primaryBannerButtonUrl?.url
                  ? bannerData?.primaryBannerButtonUrl?.url
                  : '/product-category/all-peptides/'
              }
            >
              {bannerData?.primaryBannerButtonText || 'SHOP NOW'}
            </Link>
          </div>

          {/* Image section */}
          <div className="xl:w-1/2 lg:w-[40%] md:w-[40%] md:min-h-[250px] min-h-[160px] w-full lg:pl-2 md:order-2 order-1 flex md:justify-end justify-center md:items-end items-center">
            <picture>
              {/* Mobile Image Source */}
              <source
                media="(max-width: 767px)"
                srcSet={`${mobileImageSrc} 600w`}
                sizes="354px"
              />
              {/* Desktop Image Source */}
              <source
                media="(min-width: 768px)"
                srcSet={`${desktopImageSrc} 1024w`}
                sizes="500px"
              />
              {/* Fallback image (if no media queries match) */}
              <img
                src={desktopImageSrc}
                srcSet={`${desktopImageSrc} 1024w`}
                sizes="500px"
                width={500}
                height={409}
                className="object-contain md:h-[310px] h-[180px] min-h-[180px] ml-auto"
                alt="Spartan Banner"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
